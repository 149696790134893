<template>
  <div class="Schedule bg-white">
    <Row class="px-1">
      <i-col span="24" class="align-justify">
        <FullCalendar ref="myCalendar" :options="calendarOptions" :custom-buttons="customButtons" />
      </i-col>
    </Row>
    <Modal
      v-model="scheduleSetModel"
      draggable
      scrollable
      title="排班设置"
      ok-text="确定"
      cancel-text="取消"
      width="600"
    >
      <i-form :model="setForm" :label-width="70">
        <Row>
          <i-col span="12">
            <FormItem label="提前">
              <i-select size="large" v-model="setForm.ahead">
                <Option :value="24">24小时</Option>
                <Option :value="36">36小时</Option>
                <Option :value="48">48小时</Option>
                <Option :value="60">60小时</Option>
                <Option :value="72">72小时</Option>
              </i-select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="排班">
              <i-select size="large" v-model="setForm.days">
                <Option :value="7">7天</Option>
                <Option :value="14">14天</Option>
                <Option :value="0">休息</Option>
              </i-select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="上午">
              <TimePicker
                type="timerange"
                size="large"
                v-model="setForm.morning"
                format="HH:mm"
                :steps="[1, 30]"
                placement="bottom-end"
                style="width:100%"
              ></TimePicker>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="下午">
              <TimePicker
                type="timerange"
                size="large"
                v-model="setForm.afternoon"
                format="HH:mm"
                :steps="[1, 30]"
                placement="bottom-end"
                style="width:100%"
              ></TimePicker>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="晚上">
              <TimePicker
                type="timerange"
                size="large"
                v-model="setForm.night"
                format="HH:mm"
                :steps="[1, 30]"
                placement="bottom-end"
                style="width:100%"
              ></TimePicker>
            </FormItem>
          </i-col>
        </Row>
      </i-form>
    </Modal>
    <Modal
      v-model="showReserve"
      draggable
      scrollable
      title="排班信息"
      ok-text="确定"
      cancel-text="取消"
      width="700"
    >
      <i-form :model="reserveInfo" :label-width="70">
        <Row>
          <i-col span="12">
            <FormItem label="排班事项">
              <i-Input v-model="reserveInfo.reserveTitle" readonly size="large"></i-Input>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="咨询人员">
              <i-Input v-model="reserveInfo.reserveUser" readonly size="large"></i-Input>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="联系电话">
              <i-Input v-model="reserveInfo.phone" readonly size="large"></i-Input>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="咨询日期">
              <i-Input v-model="reserveInfo.reserveDate" readonly size="large"></i-Input>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="咨询时间">
              <i-Input v-model="reserveInfo.reserveTime" readonly size="large"></i-Input>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="咨询方式">
              <i-Input v-model="reserveInfo.consultType" readonly size="large"></i-Input>
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="咨询地址">
              <i-Input v-model="reserveInfo.address" readonly size="large"></i-Input>
            </FormItem>
          </i-col>
        </Row>
      </i-form>
    </Modal>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

export default {
  name: "Schedule",
  components: {
    FullCalendar,
  },
  data() {
    return {
      customButtons: {
        prevweek: {
          // this overrides the prev button
          text: "上一周",
          click: () => {
            console.log("eventPrev");
            let calendarApi = this.$refs.myCalendar.getApi();
            calendarApi.prev();
          },
        },
        nextweek: {
          // this overrides the next button
          text: "下一周",
          click: () => {
            console.log("eventNext");
            let calendarApi = this.$refs.myCalendar.getApi();
            calendarApi.next();
          },
        },
      },
      calendarOptions: {
        height: "auto",
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "timeGridWeek",
        events: [
          {
            reserveId: "1",
            reserveTitle: "冷清辉-咨询",
            reserveUser: "冷清辉",
            phone: "17612052858",
            reserveDate: "2020-12-13",
            reserveTime: "08:00:00 - 08:50:00",
            consultType: "面对面咨询",
            address: "南昌市东湖区八一大楼819号室",
            title: "冷清辉-咨询",
            date: "2020-12-13 10:00:00",
            classNames: ["inv"],
          },
          {
            reserveId: "2",
            reserveTitle: "魏天义-咨询",
            reserveUser: "冷清辉",
            phone: "17612052858",
            reserveDate: "2020-12-13",
            reserveTime: "08:00:00 - 08:50:00",
            consultType: "面对面咨询",
            address: "南昌市东湖区八一大楼819号室",
            title: "魏天义-咨询",
            date: "2020-12-14 09:00:00",
            classNames: ["inv"],
          },
          {
            reserveId: "3",
            reserveTitle: "邵雄涛-咨询",
            reserveUser: "冷清辉",
            phone: "17612052858",
            reserveDate: "2020-12-13",
            reserveTime: "08:00:00 - 08:50:00",
            consultType: "面对面咨询",
            address: "南昌市东湖区八一大楼819号室",
            title: "邵雄涛-咨询",
            date: "2020-12-15 09:10:00",
            classNames: ["inv"],
          },
          {
            reserveId: "4",
            reserveTitle: "于文章-咨询",
            reserveUser: "冷清辉",
            phone: "17612052858",
            reserveDate: "2020-12-13",
            reserveTime: "08:00:00 - 08:50:00",
            consultType: "面对面咨询",
            address: "南昌市东湖区八一大楼819号室",
            title: "于文章-咨询",
            date: "2020-12-15 10:30:00",
            classNames: ["inv"],
          },
          {
            reserveId: "5",
            reserveTitle: "余青霞-咨询",
            reserveUser: "冷清辉",
            phone: "17612052858",
            reserveDate: "2020-12-13",
            reserveTime: "08:00:00 - 08:50:00",
            consultType: "面对面咨询",
            address: "南昌市东湖区八一大楼819号室",
            title: "余青霞-咨询",
            date: "2020-12-16 10:30:00",
            classNames: ["cal"],
          },
          {
            reserveId: "6",
            reserveTitle: "余文峰-咨询",
            reserveUser: "冷清辉",
            phone: "17612052858",
            reserveDate: "2020-12-13",
            reserveTime: "08:00:00 - 08:50:00",
            consultType: "面对面咨询",
            address: "南昌市东湖区八一大楼819号室",
            title: "余文峰-咨询",
            date: "2020-12-17 13:00:00",
            classNames: ["cal"],
          },
          {
            reserveId: "7",
            reserveTitle: "于君才-咨询",
            reserveUser: "冷清辉",
            phone: "17612052858",
            reserveDate: "2020-12-13",
            reserveTime: "08:00:00 - 08:50:00",
            consultType: "面对面咨询",
            address: "南昌市东湖区八一大楼819号室",
            title: "于君才-咨询",
            date: "2020-12-18 21:00:00",
            classNames: ["cal"],
          },
        ],
        weekNumbers: true, //是否在视图左边显示这是第多少周，默认false
        locale: "zh-cn",
        headerToolbar: {
          left: "title prev next dayGridMonth timeGridWeek",
          center: "",
          right: "",
        },
        buttonText: {
          month: "月",
          week: "周",
        },
        fixedWeekCount: false,
        handleWindowResize: true,
        weekNumberCalculation: "ISO",
        views: {
          //对应周视图调整
          timeGridWeek: {
            slotMinTime: "08:00", //周视图开始时间
            slotMaxTime: "22:00", //周视图结束时间
            displayEventTime: true, //是否显示时间
          },
        },
        select: this.handleDateSelect, //选择日期事件，可响应一个新建日程的事件
        eventClick: this.handleEventClick, //点击事件对象事件
        allDaySlot: false, //all-day栏目
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          meridiem: true,
          hour12: true, //设置时间为24小时
        },
        displayEventEnd: true, //所有视图显示结束时间
        dateClick: this.handleDateClick,
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
      },
      reserveInfo: {},
      theight: window.innerHeight - 175,
      dateRange: [],
      dateOption: {
        disabledDate(date) {
          return (
            date &&
            (date.valueOf() < Date.now() ||
              date.valueOf() > Date.now() + 84600000 * 15)
          );
        },
      },
      counselorSelect: 0,
      counselors: [],
      inputValue: "",
      recordTotal: 0,
      tData: [],
      scheduleSetModel: false,
      showReserve: false,
      setForm: {
        ahead: 24,
        days: 7,
        morning: ["09:00", "12:00"],
        afternoon: ["13:30", "17:30"],
        night: ["19:00", "21:00"],
      },
      counselor: {
        counselorId: 1001,
        counselorName: "于文章",
        ahead: 24,
        days: 7,
        morning: ["09:00", "12:00"],
        afternoon: ["13:30", "17:30"],
        night: ["19:00", "21:00"],
      },
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.createSchedule(this.counselor);
    this.$get("Reserve/GetConsultantBySelect", {
      requestName: "jaksldjaksdnq",
    }).then((res) => {
      this.counselors = res.data.responseList;
    });
  },
  methods: {
    changeMonth(start, end, current) {
      console.log(start, end, current);
    },
    handleDateClick(e) {},
    handleDateSelect(selectInfo) {},
    handleEventClick(clickInfo) {
      this.reserveInfo = clickInfo.event._def.extendedProps;
      this.showReserve = true;
    },
    appointSchedule(row) {
      console.log(row);
    },
    scheduleSet() {
      this.scheduleSetModel = true;
    },
    // 由于排班仅预约时有用，没预约的将成为无用数据，数据库保存从业者的排班设置，因此由接口根据从业者的排班设置生成。
    createSchedule(counselor) {
      // 根据当前时间确定24小时以后时间戳
      let timeStamp = Date.now() + counselor.ahead * 3600000;
      this.setStartTime(timeStamp, "10:00");
      // 确定起始时间
    },
    setStartTime(timeStamp, compareTime) {
      // 确定24小时以后的时间
      let shiFen = compareTime.split(":");
      let timestr = this.myjs.getDateTimeNum(timeStamp);
      let referenceTime = [
        timestr[0],
        timestr[1],
        timestr[2],
        shiFen[0],
        shiFen[1],
        "00",
      ];
      let compareStamp = this.myjs.getTimeStamp(referenceTime);
      return timeStamp <= compareStamp;
    },
  },
};
</script>

<style lang="less">
.fc-daygrid-event {
  .fc-daygrid-event-dot {
    display: none;
  }
  &.cal {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #5289ff;
    background-color: #eef3ff;
    .fc-event-title {
      color: #333;
    }
  }
  &.inv {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #ffa327;
    background-color: #fff6e9;
    .fc-event-title {
      color: #333;
    }
  }
}
.fc-timegrid-event {
  .fc-daygrid-event-dot {
    display: none;
  }
  &.cal {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #5289ff;
    background-color: #eef3ff;
    .fc-event-title {
      color: #333;
    }
  }
  &.inv {
    padding-left: 5px;
    color: #333;
    border-radius: 0;
    border: none;
    border-left: 2px solid #ffa327;
    background-color: #fff6e9;
    .fc-event-title {
      color: #333;
    }
  }
}
.fc .fc-toolbar-title {
  display: inline-block;
  vertical-align: middle;
}
.fc-theme-standard th {
  height: 50px;
  line-height: 50px;
}
.fc-theme-standard tr {
  height: 25px;
  line-height: 25px;
}
.fc-theme-standard td {
  height: 25px;
  line-height: 25px;
  border-top-color: transparent;
}
.fc .fc-button-primary {
  background-color: #2d8cf0;
  border-color: #2d8cf0;
}
.fc .fc-button-primary:hover {
  background-color: #2d8cf0;
  border-color: #2d8cf0;
}
.fc-toolbar-title {
  color: #2d8cf0;
}
.fc-v-event .fc-event-main {
  color: #333;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #19be6b;
  border-color: #19be6b;
}
</style>
